<template>
  <div class="privacy wrapper page-wrap">
    <div class="h1">唐山市人力资源服务产业园信息化管理系统服务政策与隐私协议</div>
    <div>
      您的个人信息安全对您和我们都至关重要，我们致力为每位用户提供安全的互联网环境。按照法律要求，我们采取相应的安全控制保护措施，对您的个人信息和其他数据进行严格保密，唐山市人力资源服务产业园信息化管理系统（或简称“我们”）制定本《隐私政策》（下称“本隐私政策”）本隐私政策适用于唐山市人力资源服务产业园信息化管理系统。
    </div>
    <div>
      在使用我们产品或服务前，请您务必仔细阅读并透彻理解本文全部内容后，选择是否同意本隐私政策的内容以及是否同意使用我们的产品或服务，如果您不同意本隐私政策的内容，将可能导致我们的产品或服务无法正常运行，或者无法达到我们期待的服务效果，或者无法达到您期待的个人信息保护状态，对此，您应立即停止访问或使用我们的产品或服务。您使用或继续使用我们提供的产品或服务的行为，或者您在使用或继续使用我们提供的产品或服务的行为之前没有以任何明示的方式向我们表达异议，都表示您充分理解和同意本《隐私政策》（包括更新版本）的全部内容。
    </div>
    <div>
      1.我们的服务旨在为入园企业提供便捷、高效的体验，确保用户能够安全、稳定地访问和使用唐山市人力资源服务产业园信息化管理系统的相关功能。
    </div>
    <div>
      2.用户在使用我们系统时，需要遵守相关法律法规和规定，不得进行任何违法、违规或侵犯他人权益的行为。
    </div>
    <div>3.我们将采取必要的安全措施，保护用户的登录信息和数据安全。</div>
    <div>
      4.我们将根据用户的需求和使用情况，不断优化和改进服务，提高用户体验和满意度。
    </div>
    <div>
      5.我们致力于为入园企业提供一流的产业生态环境，以及全方位的创新创业服务。我们不仅提供优质的办公空间和设施，还提供政策支持、投融资服务、人力资源服务、市场拓展服务等多元化的服务体系。
    </div>
    <div>
      6.我们将积极协调各类资源，为入园企业提供技术知识产权保护、品牌推广、政策研究与解读等增值服务。
    </div>
    <div>
      7.我们将建立完善的创新创业生态圈，鼓励企业之间进行合作与交流，共享资源，实现共赢。我们还将定期举办各类活动，如创业沙龙、技术交流会、管理培训等，以提升企业的综合竞争力。
    </div>
    <div>
      8.我们将积极引入优秀的投资机构、孵化器、加速器等合作伙伴，共同为入园企业打造一个全方位的创新创业环境。
    </div>
    <div>
      9.为了保障您的信息安全,我们在收集您的信息后,将采取各种合理必要的措施保护您的信息。例如,在技术开发环境当中,我们仅使用经过去标识化处理的信息进行统计分析；对外提供研究报告时,我们将对报告中所包含的信息进行去标识化处理。我们会将去标识化后的信息与可用于恢复识别个人的信息分开存储,确保在针对去标识化信息的后续处理中不重新识别个人。
    </div>
    <div>
      10.我们为尊重并保护用户的隐私权。我们将严格遵守国家相关法律法规，确保用户的个人信息不被泄露或滥用。
    </div>
    <div>
      11.我们将仅在必要的情况下收集、使用和共享用户的个人信息。例如，为了提供服务、进行园区管理、满足法律法规要求等。我们将确保这些信息的收集和使用符合相关法律法规的规定。
    </div>
    <div>
      12.我们将采取合理的安全措施来保护用户的个人信息，我们将防止信息的丢失、泄露、损坏或篡改。
    </div>
    <div>
      13.未经您同意，我们不会与任何无关第三方披露您的信息。我们可能将您的信息与我们的关联公司、第三方服务提供商、承包商及代理分享，仅用作下列用途：
    </div>
    <div>（1）提供“信息收集范围”“信息使用”中的相应功能或服务所必需。</div>
    <div>（2）履行我们在服务协议或本隐私政策中的义务和行使我们的权利。</div>
    <div>
      （3）如我们与任何上述第三方分享您的信息，我们将努力确保第三方在使用您的信息时遵守本声明及我们要求其遵守的其他适当的保密和安全措施。
    </div>
    <div>
      （4）随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的信息有可能作为此类交易的一部分而被转移。我们将遵守相关法律法规的要求，在转移前通知（包括逐一送达或公告方式）您，确保信息在转移时的机密性，以及变更后继续履行相应责任和义务。
    </div>
    <div>14.我们不会与其他组织和个人共享您的用户信息，但以下情况除外。</div>
    <div>
      （1）在获取明确同意的情况下共享，获得您的明确同意后，我们会与其他方共享您的用户信息。
    </div>
    <div>
      （2）在法定情形下的共享：我们可能会根据法律法规规定、诉讼、仲裁解决需要，或按行政、司法机关依法提出的要求，对外共享您的用户信息。
    </div>
    <div>
      （3）为了更好的服务您，我们可能会向唐山市人力资源服务产业园信息化管理系统的合作平台，共享您的用户信息。
    </div>
    <div>
      （4）为了促成办理服务或协助解决争议，某些情况下只有共享您的用户信息，才能促成办理或处理您与他人的纠纷或争议。
    </div>
    <div>
      15.本隐私政策更新：我们可能适时修订本隐私政策的条款，未经您的明确同意，我们不会限制您按照本隐私政策所享有的权利。当隐私政策发生重大变更时，我们将以弹窗的方式及时告知您，并在弹窗提示您需要再次授权同意本隐私政策的条款。
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
    .privacy{
        margin-top: 20px;
        padding:50px 0;
        >div{
            font-size: 18px;
            line-height: 40px;
            text-indent: 36px;
        }
        .h1{
            font-size: 24px;
            font-weight: bold;
            margin: 10px 0;
            text-align: center;
        }
    }
</style>
